import { SelectItem } from "@/client/components/ui/FormFields/InputSelect/InputSelect.types";
import { countryIcons, languageIcons } from "@/client/definitions/locales";
import { UserCountryCodes, UserLanguagesEnum } from "@/client/domains/user/types";

export const mapCountryToLang = (countryCode: string) => {
  const localeCode = countryCode.toLowerCase();
  return localeCode === UserCountryCodes.gb ? UserLanguagesEnum.en : localeCode;
};

export const mapCountryWithIcon = <T>(item?: T & SelectItem): (T & SelectItem) | null => {
  if (!item || !item.value) {
    return null;
  }
  const localeItem = countryIcons.find((el) => el.value === item.value);
  return {
    ...item,
    ...(localeItem?.icon && {
      icon: localeItem.icon,
    }),
  };
};
export const mapLangWithIcon = (item?: SelectItem): SelectItem => {
  if (!item || !item.value) {
    return null;
  }
  const locale = mapCountryToLang(item.value);
  const localeItem = languageIcons.find((el) => el.value === locale);
  return {
    ...item,
    ...(localeItem?.icon && {
      icon: localeItem.icon,
    }),
  };
};

export const mapCountriesToIcons = <T>(items: (T & SelectItem)[]): ((T & SelectItem) | null)[] =>
  items.map((item) => mapCountryWithIcon(item));
export const mapLangsToIcons = (items: SelectItem[]): SelectItem[] =>
  items.map((item) => mapLangWithIcon(item));

export const getCountryItemByLocale = (items: SelectItem[], countryCode: string): SelectItem => {
  return items.find((el) => el?.value === countryCode.toLocaleLowerCase()) || null;
};
export const getLangItemByLocale = (items: SelectItem[], countryCode: string): SelectItem => {
  const locale = mapCountryToLang(countryCode);
  const item = items.find((el) => el?.value === locale);
  return mapLangWithIcon(item);
};
