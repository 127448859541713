// Core
import { cloneElement } from "react";
import cx from "classnames";

// Definitions
import { ModalProps as AntdModalProps } from "antd";
import { modalDefaultWidth } from "@/client/definitions/modals";

// Components
import { Modal as AntModal } from "antd";

// Utils
import st from "./styles.module.css";

type ModalProps = AntdModalProps & {
  children: React.ReactElement;
  gutter?: "none" | "small" | "middle" | "large";
  autoWidth?: boolean;
  width?: number;
  layoutType?: string;
  isCentered?: boolean;
  testId?: string;
  closeBtnType?: "outside" | "inside";
};

type CalculateModalWidthPros = {
  width?: number;
  autoWidth: boolean;
};
const calculateModalWidth = ({ width, autoWidth }: CalculateModalWidthPros) => {
  if (width) {
    return width;
  }

  return autoWidth ? "auto" : modalDefaultWidth;
};

export const Modal = (props: ModalProps) => {
  const {
    children,
    open,
    onOk,
    onCancel,
    isCentered,
    width,
    autoWidth = false,
    zIndex = 1000,
    wrapClassName = "",
    gutter = "large",
    testId = "modal-root",
    closeBtnType = "outside",
  } = props;

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.preventDefault();
    onCancel?.(e);
  };

  const modalStyle = cx(st.modal, {
    [st[`close-btn-type-${closeBtnType}`]]: Boolean(closeBtnType),
    [st[`modal-gutter-${gutter}`]]: Boolean(gutter),
  });

  const modalWidth = calculateModalWidth({ width, autoWidth });

  return (
    <AntModal
      data-testid={testId}
      className={modalStyle}
      width={modalWidth}
      open={open}
      centered={isCentered ?? true}
      footer={null}
      destroyOnClose
      onOk={onOk}
      onCancel={handleCancel}
      wrapClassName={wrapClassName}
      zIndex={zIndex}
    >
      {cloneElement(children, { onClose: handleCancel })}
    </AntModal>
  );
};
