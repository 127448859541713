// Utils
import { verifyBuild } from "../../common/utils/verify-build";

// Base
const API_PROTOCOL = "http";
const HOST = "app.dev";
export const API_PATH = "/api";
const PORT = 3000;
// Base e2e
const E2E_TEST = process.env.ENV_TEST === "e2e";
const E2E_HOST = "localhost";
const E2E_PORT = 3600;
const CLIENT_SSR_API_URL_HTTP_E2E = `${API_PROTOCOL}://${E2E_HOST}:${E2E_PORT}`;

// Runtime
const { isDevelopment } = verifyBuild();

// API Client base
const CLIENT_API_BASE_HOST = process.env.NEXT_PUBLIC_CLIENT_HOST || HOST;
const CLIENT_API_BASE_PORT = process.env.PORT || PORT;
const CLIENT_API_BASE_HTTP_PROTOCOL =
  process.env.NEXT_PUBLIC_CLIENT_API_HTTP_PROTOCOL || API_PROTOCOL;

// API Client prefix
const CLIENT_API_URL_HTTP_PREFIX = isDevelopment
  ? ""
  : `${CLIENT_API_BASE_HTTP_PROTOCOL}://${CLIENT_API_BASE_HOST}`;

// API Client
export const CLIENT_API_URL_HTTP_REST = `${CLIENT_API_URL_HTTP_PREFIX}${API_PATH}`;

// API Client SSR prefix
const CLIENT_SSR_API_URL_HTTP_PREFIX = isDevelopment
  ? `${CLIENT_API_BASE_HTTP_PROTOCOL}://${CLIENT_API_BASE_HOST}:${CLIENT_API_BASE_PORT}`
  : `${CLIENT_API_BASE_HTTP_PROTOCOL}://${CLIENT_API_BASE_HOST}`;

// API Client for SSR
export const CLIENT_SSR_API_URL_HTTP_REST = E2E_TEST
  ? CLIENT_SSR_API_URL_HTTP_E2E
  : CLIENT_SSR_API_URL_HTTP_PREFIX;

export const QS_QUERY_CONFIG = isDevelopment ? { encode: false } : { encode: true };
